import startOfToday from "date-fns/start_of_today";
import format from "date-fns/format";
import isAfter from "date-fns/is_after";
import isBefore from "date-fns/is_before";
import isSame from "date-fns/is_same_second";
import differenceInDays from "date-fns/difference_in_days";
import getDay from "date-fns/get_day";
import setDay from "date-fns/set_day";
import addDays from "date-fns/add_days";

const isSameOrBefore = (date, dateToCompare) => {
    return isSame(date, dateToCompare) || isBefore(date, dateToCompare);
};

const isSameOrAfter = (date, dateToCompare) => {
    return isSame(date, dateToCompare) || isAfter(date, dateToCompare);
};

const logic = {
    DAY_LIMIT: 3,
    _cloneSubscription: function (subscription) {
        return JSON.parse(JSON.stringify(subscription));
    },

    _isSubscriptionLateOrCancelled: function (subscription) {
        if (!subscription.late) return false;

        if (this.isSubscriptionCancelled(subscription)) return true;

        return this._computeLate(subscription);
    },

    _isSubscriptionLate: function (subscription) {
        if (!subscription.late) return false;

        return this._computeLate(subscription);
    },

    _computeLate(subscription) {
        var today = startOfToday();
        var todayPeriod = this._period(today);
        var latePeriod = this._period(subscription.late.lastingAt);
        return latePeriod >= todayPeriod && isSameOrBefore(today, subscription.late.lastingAt);
    },

    canRemoveLate(subscription) {
        if (!subscription.late) {
            return false;
        }

        const today = startOfToday();
        return differenceInDays(subscription.late.lastingAt, today) >= this.DAY_LIMIT;
    },

    hasLateDueToClosing(subscription) {
        return this._isSubscriptionLate(subscription) && subscription.late.reason === "closing";
    },

    _activeSubscription: function (baseSubscription) {
        var subscription = this._cloneSubscription(baseSubscription);

        if (this._isSubscriptionLateOrCancelled(subscription)) {
            subscription.place = subscription.late.place;
            subscription.startingAt = subscription.late.lastingAt;
        }

        delete subscription.late;
        return subscription;
    },

    isSubscriptionCancelled: function (subscription) {
        return !subscription.startingAt;
    },

    reschedule: function (baseSubscription, newPlace, closed, nolimit) {
        var subscription = this._cloneSubscription(baseSubscription);
        var activeSubscription = this._activeSubscription(subscription);

        var limit = nolimit ? 0 : this.DAY_LIMIT;

        var schedules = this._schedules(
            activeSubscription.rate,
            activeSubscription.place.opening,
            newPlace.opening,
            activeSubscription.startingAt,
            closed,
            limit,
        );

        if (baseSubscription.formula === "uniq") {
            if (!schedules) return subscription;

            if (schedules.remaining) {
                subscription.startingAt = schedules.remaining;
            } else {
                subscription.place = newPlace;
                subscription.startingAt = schedules.upcoming;
            }
        } else {
            if (schedules.remaining) {
                if (!this._isSubscriptionLateOrCancelled(subscription))
                    subscription.late = {
                        place: subscription.place,
                        quantity: subscription.quantity,
                        lastingAt: schedules.remaining,
                        reason: "forced",
                    };
            } else {
                delete subscription.late;
            }

            subscription.place = newPlace;
            subscription.startingAt = schedules.upcoming;
        }
        return subscription;
    },

    withNewQuantity(baseSubscription, quantity) {
        const activeSubscription = this._activeSubscription(baseSubscription);

        const rescheduled = this.reschedule(
            activeSubscription,
            activeSubscription.place,
            activeSubscription.place.closing,
        );

        return {
            ...baseSubscription,
            quantity: quantity,
            startingAt: rescheduled.startingAt,
            late: rescheduled.late,
        };
    },

    _pauseBackward: function (baseSubscription, periodCount, closed, limit) {
        var subscription = this._cloneSubscription(baseSubscription);
        var activeSubscription = this._activeSubscription(subscription);
        var nextDelivery = this._basicDeliveryDate(activeSubscription);
        if (!nextDelivery) return baseSubscription; // uniq

        var today = startOfToday();

        if (this._isSubscriptionLateOrCancelled(subscription)) {
            do {
                subscription.startingAt = nextDelivery = this.previousAvailableWeek(
                    this.nextDeliveryDate(subscription),
                    closed,
                );
            } while (++periodCount < 0);

            subscription.startingAt = nextDelivery = this._toOpenDelivery(nextDelivery, closed, true);
            if (this.canRemoveLate(subscription)) delete subscription.late;
        } else if (differenceInDays(nextDelivery, today) >= limit) {
            /* can be moved */
            delete subscription.late;
            do {
                subscription.startingAt = nextDelivery = this.previousAvailableWeek(nextDelivery, closed);
            } while (++periodCount < 0);

            subscription.startingAt = nextDelivery = this._toOpenDelivery(nextDelivery, closed, true);
        }

        var canBeMoved = nextDelivery && differenceInDays(nextDelivery, today) >= limit;

        return canBeMoved ? subscription : baseSubscription;
    },

    _pauseForward: function (subscription, periodCount, closed, limit) {
        var activeSubscription = this._activeSubscription(subscription);
        var nextDelivery = this._basicDeliveryDate(activeSubscription);
        if (!nextDelivery) return subscription; // uniq

        var today = startOfToday();

        if (this._isSubscriptionLateOrCancelled(subscription)) {
            do {
                subscription.startingAt = nextDelivery = this.nextAvailableWeek(
                    this.nextDeliveryDate(subscription),
                    closed,
                );
            } while (--periodCount > 0);

            subscription.startingAt = nextDelivery = this._toOpenDelivery(nextDelivery, closed);
            if (this.canRemoveLate(subscription)) delete subscription.late;
        } else if (differenceInDays(nextDelivery, today) >= limit) {
            /* can be moved */
            delete subscription.late;
            do {
                subscription.startingAt = nextDelivery = this.nextAvailableWeek(nextDelivery, closed);
            } while (--periodCount > 0);

            subscription.startingAt = nextDelivery = this._toOpenDelivery(nextDelivery, closed);
        } else {
            if (subscription.formula === "uniq") return subscription;

            subscription.late = {
                place: subscription.place,
                quantity: subscription.quantity,
                lastingAt: nextDelivery,
                reason: "forced",
            };

            var count = 0;
            // jump to the week after the next
            // otherwise, nothing really changes for weekly subscription
            if (subscription.rate === "weekly") count = -1;

            do {
                subscription.startingAt = nextDelivery = this.nextAvailableWeek(nextDelivery, closed);
            } while (--periodCount > count);

            subscription.startingAt = nextDelivery = this._toOpenDelivery(nextDelivery, closed);
        }
        return subscription;
    },

    pause: function (baseSubscription, periodCount, closed, nolimit) {
        var limit = nolimit ? 0 : this.DAY_LIMIT;
        var subscription = this._cloneSubscription(baseSubscription);
        periodCount = periodCount || 1;

        if (periodCount < 0) return this._pauseBackward(subscription, periodCount, closed, limit);
        else return this._pauseForward(subscription, periodCount, closed, limit);
    },

    cancel: function (baseSubscription) {
        var activeSubscription = this._activeSubscription(baseSubscription);
        var nextDelivery = this._basicDeliveryDate(activeSubscription);
        if (!nextDelivery) return activeSubscription; // uniq

        var today = startOfToday();

        if (this._isSubscriptionLateOrCancelled(activeSubscription)) {
            if (this.canRemoveLate(activeSubscription)) delete activeSubscription.late;
        } else {
            /* cannot be moved */
            if (differenceInDays(nextDelivery, today) < this.DAY_LIMIT) {
                activeSubscription.late = {
                    place: activeSubscription.place,
                    quantity: activeSubscription.quantity,
                    lastingAt: nextDelivery,
                    reason: "forced",
                };
            }
        }

        delete activeSubscription.startingAt;
        return activeSubscription;
    },

    nextDeliveryDate: function (baseSubscription, closed) {
        var subscription = this._cloneSubscription(baseSubscription);

        if (this._isSubscriptionLateOrCancelled(subscription)) return this._basicDeliveryDate(subscription);

        var deliveryDate = this._basicDeliveryDate(subscription);
        if (!deliveryDate) return;

        deliveryDate = this._toOpenDelivery(deliveryDate, closed); // shift deliveryDate, before shifting nextDeliveryDate

        switch (subscription.rate) {
            case "fourweekly":
                deliveryDate = addDays(deliveryDate, 28);
                break; // eslint-disable-line max-statements-per-line
            case "biweekly":
                deliveryDate = addDays(deliveryDate, 14);
                break; // eslint-disable-line max-statements-per-line
            default: // uniq
            case "weekly":
                deliveryDate = addDays(deliveryDate, 7);
                break; // eslint-disable-line max-statements-per-line
        }
        return this._toOpenDelivery(deliveryDate, closed);
    },

    deliveryDate: function (baseSubscription, closed) {
        if (this._isSubscriptionLate(baseSubscription)) {
            return baseSubscription.late.lastingAt;
        }

        if (this.isSubscriptionCancelled(baseSubscription)) {
            return;
        }

        var activeSubscription = this._activeSubscription(baseSubscription);
        var deliveryDate = this._basicDeliveryDate(activeSubscription);
        return this._toOpenDelivery(deliveryDate, closed);
    },

    _basicDeliveryDate: function (baseSubscription) {
        var subscription = this._cloneSubscription(baseSubscription);

        return this._deliveryDate(subscription.rate, subscription.place.opening, subscription.startingAt);
    },

    _deliveryDate: function (rate, opening, startingAt) {
        if (!startingAt) return; // cancelled

        var today = startOfToday();
        if (isBefore(today, startingAt)) return startingAt;
        else if (!rate) {
            // uniq
            if (isSame(today, startingAt)) return startingAt;
            else return;
        }

        var openingDay = getDay(today); // mercredi -> 4
        var meetingDay = this._meetingDayName(opening.day);
        if (meetingDay < openingDay) meetingDay += 7;
        var nextDelivery = setDay(today, meetingDay);

        if (rate === "weekly") {
            if (isBefore(nextDelivery, startingAt)) nextDelivery = addDays(nextDelivery, 7);
        } else if (rate === "biweekly" || rate === "fourweekly") {
            var slice = rate === "biweekly" ? 2 : 4;
            var diff = this._weekDiff(nextDelivery, startingAt);
            while (diff % slice !== 0) {
                nextDelivery = addDays(nextDelivery, 7);
                diff = this._weekDiff(nextDelivery, startingAt);
            }
        }

        return nextDelivery;
    },

    deliveryOn: function (subscription, date) {
        if (!subscription || !date) return false;

        if (subscription.late && differenceInDays(subscription.late.lastingAt, date) === 0) return true;

        if (!subscription.startingAt)
            // cancelled
            return false;

        var diff = differenceInDays(date, subscription.startingAt);
        if (diff < 0) return false;

        if (subscription.formula === "uniq") return diff === 0;

        switch (subscription.rate) {
            case "fourweekly":
                return diff % 28 === 0;
            case "biweekly":
                return diff % 14 === 0;
            default:
            case "weekly":
                return diff % 7 === 0;
        }
    },

    openSlot: function (opening, closed) {
        var meetingDay = this._meetingDayName(opening.day);

        var limit = addDays(startOfToday(), this.DAY_LIMIT);
        var limitDay = getDay(limit); // mercredi -> 4
        if (meetingDay < limitDay) meetingDay += 7;
        var openSlot = setDay(limit, meetingDay);

        return this._toOpenDelivery(openSlot, closed);
    },

    previousAvailableWeek: function (openSlot, closed) {
        if (!openSlot) return;

        var previousOpenSlot = addDays(openSlot, -7);
        return this._toOpenDelivery(previousOpenSlot, closed, true);
    },

    nextAvailableWeek: function (openSlot, closed) {
        if (!openSlot) return;

        var nextOpenSlot = addDays(openSlot, 7);
        return this._toOpenDelivery(nextOpenSlot, closed);
    },

    _meetingDayName: function (day) {
        return {
            Monday: 1,
            Tuesday: 2,
            Wednesday: 3,
            Thursday: 4,
            Friday: 5,
            Saturday: 6,
            Sunday: 0,
        }[day];
    },

    _weekDiff: function (from, to) {
        if (isBefore(to, from)) return this._weekDiff(to, from);

        var fromWeek = format(from, "GGGGWW");
        var toWeek = format(to, "GGGGWW");
        var clone = from;
        var count = 0;
        while (fromWeek < toWeek) {
            clone = addDays(clone, 7);
            fromWeek = format(clone, "GGGGWW");
            count++;
        }
        return count;
    },

    _period: function (date) {
        return parseInt(format(date, "GGGGWW"), 10);
    },

    _schedules: function (rate, opening, newOpening, startingAt, closed, limit) {
        var today = startOfToday();

        var nextDelivery = this._deliveryDate(rate, opening, startingAt);
        if (!nextDelivery) return; // uniq

        var toNextDelivery = this.openSlot(newOpening);

        var currentPeriod = this._period(nextDelivery, rate);
        var targetPeriod = this._period(toNextDelivery, rate);

        /* can be moved */
        if (differenceInDays(nextDelivery, today) >= limit) {
            /* new is before */
            if (targetPeriod < currentPeriod) {
                /* move it to same week / month */
                while (targetPeriod < currentPeriod) {
                    toNextDelivery = addDays(toNextDelivery, 7);
                    targetPeriod = this._period(toNextDelivery, rate);
                }
            }
            return {upcoming: this._toOpenDelivery(toNextDelivery, closed)};
        } else {
            var gap = 0;
            // in biweekly openSlot is next week, so go one week further
            if (rate === "biweekly") gap = -1;
            else if (rate === "fourweekly") gap = -3; // in fourweekly, go even further

            while (targetPeriod + gap <= currentPeriod) {
                toNextDelivery = addDays(toNextDelivery, 7);
                targetPeriod = this._period(toNextDelivery, rate);
            }
            /* keep old and add new */
            return {upcoming: this._toOpenDelivery(toNextDelivery, closed), remaining: nextDelivery};
        }
    },

    _isBetween: function (date, closed) {
        if (!date || !closed) return false;

        return isSameOrAfter(date, closed.from) && isSameOrBefore(date, closed.to);
    },

    _toOpenDelivery: function (openSlot, closed, backward) {
        if (!openSlot) return;

        var week = !backward ? 7 : -7;

        if (closed) while (this._isBetween(openSlot, closed)) openSlot = addDays(openSlot, week);
        return openSlot;
    },

    deliveryPlace: function (subscription) {
        return this._isSubscriptionLateOrCancelled(subscription) ? subscription.late.place : subscription.place;
    },

    nextDeliveryPlace: function (subscription) {
        return subscription.place;
    },
};

export default logic;
