import Sync from "./Sync";

const deepClone = function (obj) {
    let clone = obj;
    if (Array.isArray(obj)) {
        clone = [];
        obj.forEach((item, index) => {
            clone[index] = deepClone(item);
        });
    } else if (obj instanceof Date) {
        return new Date(obj.getTime());
    } else if (obj && typeof obj === "object") {
        clone = {};
        Object.keys(obj).forEach((key) => {
            clone[key] = deepClone(obj[key]);
        });
    }
    return clone;
};

class Model {
    constructor(options) {
        options = options || {};
        this.url = options.url;
        this.props = options.props || {};
        this.sync = new Sync();

        this.attributes = {};
        this.defineProperties();
    }
    updateMethod() {
        return this.id ? "PUT" : "POST";
    }
    getURL() {
        return this.id ? this.url + "/" + this.id : this.url;
    }
    fetch(options) {
        options = options || {};

        return fetch(options.url || this.getURL(), {credentials: "include", cache: "no-store"})
            .then((res) => this.json(res))
            .then((json) => this.parseJSON(json))
            .then((json) => this.set(json, {unset: true}));
    }
    save(data, options) {
        options = options || {};

        return fetch(options.url || this.getURL(), {
            credentials: "include",
            cache: "no-store",
            method: options.method || this.updateMethod(),
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data || this.toJSON()),
        })
            .then((res) => this.json(res))
            .then((json) => this.parseJSON(json))
            .then((json) => this.set(json, {unset: true}));
    }
    saveRaw(data, options) {
        options = options || {};

        return fetch(options.url || this.getURL(), {
            credentials: "include",
            cache: "no-store",
            method: options.method || this.updateMethod(),
            headers: {
                "Content-Type": "application/json",
            },
            body: this.stringifyRaw(data || this.toJSON(), options),
        }).then((res) => this.sync.readJSON(res));
    }
    stringifyRaw(data, options) {
        if (!options.isBasicSave)
            // calling saveRaw directly should not use (possibly) overridden stringify
            return JSON.stringify(deepClone(data));

        return this.stringify(deepClone(data));
    }
    destroy(options) {
        options = options || {};

        return fetch(options.url || this.getURL(), {
            credentials: "include",
            cache: "no-store",
            method: "DELETE",
        })
            .then((res) => this.json(res))
            .then(() => this.clear());
    }
    json(res) {
        if (res.status >= 400) {
            return res.json().then((err) => {
                throw err;
            });
        }

        return res.json();
    }
    parse(data) {
        return data;
    }
    parseJSON(item) {
        return this.parseItem(this.parse(item));
    }
    parseItem(item) {
        const parsed = {};
        Object.keys(this.props).forEach((prop) => {
            if (item[prop] === undefined) {
                parsed[prop] = undefined;
                return;
            }

            if (item[prop] === null) {
                parsed[prop] = null;
                return;
            }

            switch (this.props[prop]) {
                case "string":
                    parsed[prop] = String(item[prop]);
                    break;
                case "number":
                    parsed[prop] = Number(item[prop]);
                    break;
                case "boolean":
                    parsed[prop] = Boolean(item[prop]);
                    break;
                case "array":
                    parsed[prop] = Array.from(item[prop]);
                    break;
                case "object":
                    parsed[prop] = Object.assign({}, item[prop]);
                    break;
                case "date":
                    parsed[prop] = item[prop] && new Date(item[prop]);
                    break;
                default:
                    break;
            }
        });

        return parsed;
    }
    set(data, options) {
        options = options || {};

        Object.keys(this.props).forEach((prop) => {
            if (options.unset || data[prop] !== undefined) this[prop] = data[prop];
        });
        return this;
    }
    clear() {
        Object.keys(this.props).forEach((prop) => {
            this.attributes[prop] = undefined;
        });
        return this;
    }
    defineProperties() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const model = this;
        Object.keys(this.props).forEach((prop) => {
            Object.defineProperty(this, prop, {
                get: function () {
                    return model.attributes[prop];
                },
                set: function (value) {
                    model.attributes[prop] = value;
                },
            });
        });
    }
    toJSON() {
        return Object.assign({}, this.attributes);
    }
}

export default Model;
