import format from "date-fns/format";
import localeFR from "date-fns/locale/fr";
import isSame from "date-fns/is_same_second";
import isSameDay from "date-fns/is_same_day";
import parseDate from "date-fns/parse";
import isAfter from "date-fns/is_after";
import isBefore from "date-fns/is_before";
import startOfToday from "date-fns/start_of_today";
import addDays from "date-fns/add_days";
import diff from "date-fns/difference_in_days";
import getMonth from "date-fns/get_month";
import cloneDeep from "lodash/cloneDeep";

import Logic from "../subscription/SubscriptionLogic";
import SubscriptionModel from "../subscription/SubscriptionModel";
import {DOZEN, ProductType, RestrictedChannel, UnitType} from "../const";
import {isEmpty, uniq} from "lodash";
import {config} from "@app/config";

const SPIDER_CRAB_PRODUCT_ID = "615c15f659ed4382344da388";
const MALUS = 1.15;
const fr = {locale: localeFR};

const displayMethods = {
    "Aquaculture extensive": "Aquaculture",
    "Canne": "Pêché à la ligne",
    "Carrelet": "Pêché au filet",
    "Cueillette": "Cueillette",
    "Drague à main": "Pêché au filet",
    "Élevage sur corde": "Élevé sur corde",
    "Élevage sur table": "Élevé sur table",
    "Filet dérivant": "Pêché au filet",
    "Filet droit calé": "Pêché au filet",
    "Filet tournant": "Pêché au filet",
    "Filet trémail": "Pêché au filet",
    "Nasse et casier": "Pêché au casier",
    "Ostréiculture traditionnelle": "Ostréiculture",
    "Palangre": "Pêché à la ligne",
    "Paluderie": "Paluderie",
    "Pêche à pied": "Pêché à pied",
    "Plongée": "Pêché en plongée",
    "Pot": "Pêché au pot",
    "Traîne": "Pêché à la ligne",
    "Verveux": "Pêché au filet",
};

const displayAgreements = {
    "Aile": {
        before: true,
    },
    "Blanc": {
        before: true,
    },
    "Chute": {
        before: true,
    },
    "Darne": {
        before: true,
    },
    "Filet": {
        before: true,
    },
    "Foie": {
        before: true,
    },
    "Joue": {
        before: true,
    },
    "Laitance": {
        before: true,
    },
    "Longe": {
        before: true,
    },
    "Médaillon": {
        before: true,
    },
    "Oeufs": {
        before: true,
    },
    "Pavé": {
        before: true,
    },
    "Queue": {
        before: true,
    },
    "Ribs": {
        before: true,
    },
    "Tentacules": {
        before: true,
    },
    "Tête": {
        before: true,
    },
    "Tronçon": {
        before: true,
    },
    "Ventrèche": {
        before: true,
    },
    "Fleur": {
        before: true,
    },
    "Cuit": {
        before: true,
    },
    "Pelé": {
        feminine: "Pelée",
        plural: "Pelés",
        feminineplural: "Pelées",
    },
    "Vidé": {
        feminine: "Vidée",
        plural: "Vidés",
        feminineplural: "Vidées",
    },
    "Dessablé": {
        feminine: "Dessablée",
        plural: "Dessablés",
        feminineplural: "Dessablées",
    },
    "Non dessablé": {
        feminine: "Non Dessablée",
        plural: "Non Dessablés",
        feminineplural: "Non Dessablées",
    },
    "Entier": {
        feminine: "Entière",
        plural: "Entiers",
        feminineplural: "Entières",
    },
    "Frais": {
        feminine: "Fraîche",
        plural: "Frais",
        feminineplural: "Fraîches",
    },
    "Ikejime entier": {
        feminine: "Ikejime entière",
        plural: "Ikejime entiers",
        feminineplural: "Ikejime entières",
    },
    "Ikejime vidé": {
        feminine: "Ikejime vidée",
        plural: "Ikejime vidés",
        feminineplural: "Ikejime vidées",
    },
    "Sec": {
        feminine: "Sèche",
        plural: "Sèches",
        feminineplural: "Sèches",
    },
    "Séché": {
        feminine: "Séchée",
        plural: "Séchés",
        feminineplural: "Séchées",
    },
    "Vivant": {
        feminine: "Vivante",
        plural: "Vivants",
        feminineplural: "Vivantes",
    },
    "Vivant ébarbé": {
        feminine: "Vivante ébarbée",
        plural: "Vivants ébarbés",
        feminineplural: "Vivantes ébarbées",
    },
    "Non ébarbé": {
        feminine: "Non ébarbée",
        plural: "Non ébarbés",
        feminineplural: "Non ébarbées",
    },
    "Vivant épaté": {
        feminine: "Vivante épatée",
        plural: "Vivants épatés",
        feminineplural: "Vivantes épatées",
    },
    "À décoquiller": {
        feminine: "À décoquiller",
        plural: "À décoquiller",
        feminineplural: "À décoquiller",
    },
    "Laiteux": {
        feminine: "Laiteuse",
        plural: "Laiteux",
        feminineplural: "Laiteuses",
    },
};

const unitPriceBy100gThreshold = 75;

const _ = {
    format(date, value) {
        return !date ? "" : format(date, value, fr);
    },
    toPrice(cent, coupon, options = {}) {
        const {showSign = false} = options;
        let centDiscounted;
        let percentDiscounted;

        if (coupon && coupon.discountPrice) {
            centDiscounted = coupon.discountPrice;
        } else if (coupon && coupon.discountPercentage) {
            percentDiscounted = coupon.discountPercentage;
        }

        if (centDiscounted) {
            cent = Math.max(0, cent - centDiscounted);
        }
        if (percentDiscounted) {
            cent *= 1 - percentDiscounted / 100;
        }

        let euro = cent / 100;
        if (euro - Math.ceil(euro)) {
            euro = euro.toFixed(2);
        }

        let sign = showSign && euro > 0 ? "+" : "";

        return `${sign}${euro}€`;
    },
    computePrice(rate, userFormula) {
        let price;
        switch (rate) {
            case "weekly":
                price = config.pricing.subscription.weekly.price.ttc;
                break;
            case "biweekly":
                price = config.pricing.subscription.biweekly.price.ttc;
                break;
            case "fourweekly":
                price = config.pricing.subscription.fourweekly.price.ttc;
                break;
            case null:
                price = userFormula || config.pricing.subscription.uniq.price.ttc;
                break; // eslint-disable-line max-statements-per-line
            default:
                price = 0;
        }
        return price;
    },
    capitalize(string) {
        if (!string) return "";
        return string.toUpperCase();
    },
    capitalizeFirst(string) {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getAllergies(allergies) {
        allergies = allergies || {};
        let text = "";
        if (allergies.shells) text += "Coquillages, ";
        if (allergies.crustaceans) text += "Crustacés, ";
        if (allergies.fishes) text += "Poissons, ";
        if (allergies.oysters) text += "Huîtres, ";
        if (allergies.others) text += allergies.others;

        if (!text) text = "Aucune allergie / aversion";
        else text = text.replace(/,\s*$/, "");
        return text;
    },
    getAllergen(product) {
        switch (product.type) {
            case ProductType.Fish:
            case ProductType.Crustacean:
                return product.type;
            case ProductType.Shell:
            case ProductType.Cephalopod:
                return "Mollusque";
            default:
                return "-";
        }
    },
    subscriptionHasFreeDelivery(place, quantity) {
        return !place || !place.opening || !place.opening.extra || config.freeDeliveryComboThreshold <= quantity;
    },
    getDelivery(place, freeDelivery) {
        if (place.opening && place.opening.extra && freeDelivery) return "Livraison offerte";
        else if (place.opening && place.opening.extra) return "Livraison : " + this.toPrice(place.opening.extra);
        else return "Livraison gratuite";
    },
    getInlineLocation(place) {
        if (!place) return "";

        if (place.shipping.pickup === "home") return "à domicile au " + place.description;
        else return "chez " + place.name;
    },
    getInlineLocationType(place) {
        if (!place) return "";

        if (place.shipping.pickup === "home") return "à domicile";
        else return "chez " + place.name;
    },
    getLocation(place) {
        if (place.shipping.pickup === "home") return "Livré à domicile au " + place.description;
        else return "Chez " + place.name + ", " + place.description;
    },
    getNextLocation(place) {
        return "Puis " + this.getInlineLocation(place);
    },
    getDay(opening) {
        return ((opening && opening.schedule) || " ").split(" ")[1].toLowerCase();
    },
    getOpeningFromDay(openings, day) {
        return openings.find((opening) => opening.day === day);
    },
    getExtraOpenings(place, options = {}) {
        const {channel, date} = options;

        if (!place.extraOpenings) {
            return [];
        }

        return place.extraOpenings?.filter((extra) => {
            return (!channel || extra.channel === channel) && (!date || isSameDay(parseDate(extra.date), date));
        });
    },
    findExtraOpeningByDate(place, date, options = {}) {
        return _.getExtraOpenings(place, {...options, date})[0];
    },
    weight(opening) {
        let pos;

        if (opening.day === "Monday") pos = 0;
        if (opening.day === "Tuesday") pos = 10;
        if (opening.day === "Wednesday") pos = 20;
        if (opening.day === "Thursday") pos = 30;
        if (opening.day === "Friday") pos = 40;
        if (opening.day === "Saturday") pos = 50;
        if (opening.day === "Sunday") pos = 60;

        pos += opening.shipping.delay * 2; // same day deliveries first

        pos = opening.date ? pos : ++pos; // extraOpening first

        if (!opening.shortZipCodes)
            // restricted area second
            pos += 1;

        return pos;
    },
    getFormattedDay(day) {
        switch (day) {
            case "Monday":
                return "lundi";
            case "Tuesday":
                return "mardi";
            case "Wednesday":
                return "mercredi";
            case "Thursday":
                return "jeudi";
            case "Friday":
                return "vendredi";
            case "Saturday":
                return "samedi";
            case "Sunday":
                return "dimanche";
            default:
                return "";
        }
    },
    getHoraries(place) {
        let horaries = "";
        if (place && place.opening && place.opening.formattedSchedule)
            horaries = place.opening.formattedSchedule.split(" ").slice(2).join(" ");
        else if (place && place.opening && place.opening.schedule)
            horaries = place.opening.schedule.split(" ").slice(2).join(" ");
        return horaries;
    },
    getFormattedSchedule(place, opening) {
        if (!this.isPlaceShippedNextDay(place)) return opening.schedule;

        const day = this.getFormattedDay(this.toNextDay(opening.day));
        return `${opening.schedule.split(" ")[0]} ${day} ${opening.schedule.split(" ").slice(2).join(" ")}`;
    },
    getShortZipCode(description) {
        return description.split(" ").slice(-1)[0].substring(0, 2);
    },
    getRateLabel(rate) {
        let rateLabel = "";
        switch (rate) {
            case "weekly":
                rateLabel = "semaine";
                break;
            case "biweekly":
                rateLabel = "quinzaine";
                break;
            case "fourweekly":
                rateLabel = "mois";
                break;
            default:
                break;
        }
        return rateLabel;
    },
    getFormattedDeliveryToUpdate(subscription, delivery) {
        const deliver = {
            at: undefined,
            and: undefined,
            until: undefined,
            updated: undefined,
            cannotGoBack: false,
        };

        if (subscription && delivery) {
            deliver.at = delivery.formattedAt;
            deliver.cannotGoBack = this.cannotGoBack(subscription);

            const until = addDays(delivery.at, -1 * Logic.DAY_LIMIT);
            const today = startOfToday();
            if (diff(until, today) < 0) deliver.and = delivery.and;
            else deliver.until = addDays(delivery.at, -1 * Logic.DAY_LIMIT);

            if (!deliver.and) {
                deliver.updated = Logic.deliveryDate(subscription, Logic.deliveryPlace(subscription).closing);
                deliver.updated = SubscriptionModel.formattedAt(subscription, deliver.updated);
            } else {
                deliver.updated = Logic.nextDeliveryDate(subscription, Logic.nextDeliveryPlace(subscription).closing);
                deliver.updated = SubscriptionModel.formattedAnd(subscription, deliver.updated);
                deliver.and = SubscriptionModel.formattedAnd(subscription, deliver.and);
            }
        }

        return deliver;
    },
    getPlace(subscription) {
        return Logic._isSubscriptionLate(subscription) ? subscription.late.place : subscription.place;
    },
    getFormattedDelivery(subscription) {
        const isUniq = subscription.formula === "uniq";

        const deliver = {
            at: undefined,
            and: undefined,
            formattedAt: undefined,
            formattedAnd: undefined,
            atPlace: subscription.place,
            andPlace: undefined,
        };

        if (isUniq) {
            deliver.at = subscription.startingAt;
            deliver.formattedAt = SubscriptionModel.formattedAt(subscription, deliver.at);
        } else if (subscription.delivery) {
            if (subscription.delivery.formattedAt) {
                deliver.at = subscription.delivery.at;
                deliver.formattedAt = subscription.delivery.formattedAt;
            }

            if (subscription.delivery.formattedAnd) {
                deliver.and = subscription.delivery.and;
                deliver.formattedAnd = subscription.delivery.formattedAnd;
            }

            if (subscription.delivery.isLate && subscription.late) {
                deliver.atPlace = subscription.late.place;
                if (subscription.late.place.id !== subscription.place.id) deliver.andPlace = subscription.place;
            }
        } else {
            deliver.at = Logic.deliveryDate(subscription, Logic.deliveryPlace(subscription).closing);
            if (subscription.rate)
                deliver.and = Logic.nextDeliveryDate(subscription, Logic.nextDeliveryPlace(subscription).closing);

            deliver.formattedAt = SubscriptionModel.formattedAt(subscription, deliver.at);
            deliver.formattedAnd = SubscriptionModel.formattedAnd(subscription, deliver.and);
        }

        return deliver;
    },
    _toDelivery(subscription) {
        return {
            at: Logic.deliveryDate(subscription, Logic.deliveryPlace(subscription).closing),
            and: Logic.nextDeliveryDate(subscription, Logic.nextDeliveryPlace(subscription).closing),
            isLate: Logic._isSubscriptionLate(subscription),
        };
    },
    cannotGoBack(subscription) {
        var backward = Logic.pause(subscription, -1, subscription.place.opening.closing);
        backward.delivery = this._toDelivery(backward);

        if (!backward.startingAt) {
            return true; // cancelled
        }
        return (
            isSame(backward.startingAt, subscription.startingAt) &&
            ((backward.late && subscription.late && isSame(backward.late.lastingAt, subscription.late.lastingAt)) ||
                (!backward.late && !subscription.late))
        );
    },
    isPlaceShippedAtHome(place) {
        return place && place.shipping && place.shipping.pickup === "home";
    },
    isPlaceShippedAtRelay(place) {
        return place && place.shipping && place.shipping.pickup === "relay";
    },
    isPlaceShippedSameDay(place) {
        return place && place.shipping && place.shipping.delay === 0;
    },
    isPlaceShippedNextDay(place) {
        return place && place.shipping && place.shipping.delay === 1;
    },
    toNextDay(day) {
        switch (day) {
            case "lundi":
                day = "mardi";
                break; //eslint-disable-line max-statements-per-line
            case "mardi":
                day = "mercredi";
                break; //eslint-disable-line max-statements-per-line
            case "mercredi":
                day = "jeudi";
                break; //eslint-disable-line max-statements-per-line
            case "jeudi":
                day = "vendredi";
                break; //eslint-disable-line max-statements-per-line
            case "vendredi":
                day = "samedi";
                break; //eslint-disable-line max-statements-per-line
            case "samedi":
                day = "dimanche";
                break; //eslint-disable-line max-statements-per-line
            case "dimanche":
                day = "lundi";
                break; //eslint-disable-line max-statements-per-line
            case "Monday":
                day = "Tuesday";
                break; // eslint-disable-line max-statements-per-line
            case "Tuesday":
                day = "Wednesday";
                break; // eslint-disable-line max-statements-per-line
            case "Wednesday":
                day = "Thursday";
                break; // eslint-disable-line max-statements-per-line
            case "Thursday":
                day = "Friday";
                break; // eslint-disable-line max-statements-per-line
            case "Friday":
                day = "Saturday";
                break; // eslint-disable-line max-statements-per-line
            case "Saturday":
                day = "Sunday";
                break; // eslint-disable-line max-statements-per-line
            case "Sunday":
                day = "Monday";
                break; // eslint-disable-line max-statements-per-line
            default:
                break;
        }
        return day;
    },
    isSameOrBefore(date, dateToCompare) {
        return isSame(date, dateToCompare) || isBefore(date, dateToCompare);
    },
    isSameOrAfter(date, dateToCompare) {
        return isSame(date, dateToCompare) || isAfter(date, dateToCompare);
    },
    isBetween(date, fromDate, toDate) {
        return this.isSameOrAfter(date, fromDate) && this.isSameOrBefore(date, toDate);
    },
    getActiveClosing(place) {
        const closing = place?.opening?.closing;
        const isOpenDuringClosing =
            place.extraOpenings?.length &&
            place.extraOpenings.find((extraOpening) => this.isBetween(extraOpening.date, closing.from, closing.to));

        if (!closing || isOpenDuringClosing) {
            return;
        }

        const now = startOfToday();
        const warningDate = addDays(closing.from, -28);

        if (this.isSameOrBefore(now, closing.to) && this.isSameOrAfter(now, warningDate)) {
            let from = closing.from;
            let to = closing.to;
            if (this.isPlaceShippedNextDay(place)) {
                from = addDays(from, 1);
                to = addDays(to, 1);
            }

            return {from, to};
        }
        return null;
    },
    getClosingWarningMessage(place) {
        const closing = _.getActiveClosing(place);

        if (!closing) {
            return "";
        }

        const {from, to} = closing;
        const pattern = "dddd D MMMM";
        const isDecember = getMonth(from) === 11;

        let period;
        if (isSame(from, to)) {
            period = `le ${_.format(from, pattern)}`;
        } else {
            period = `du ${_.format(from, pattern)} au ${_.format(to, pattern)}`;
        }

        const suffix = isDecember
            ? "Notre boutique en ligne reste ouverte et garnie pour les fêtes."
            : 'Pour vous faire livrer, cliquez sur "Modifier ma formule > Modifier mon lieu" puis "Rapprocher mon Casier".';
        return `Les livraisons dans votre point relais sont suspendues ${period}. ${suffix}`;
    },
    _getRatio(unit) {
        switch (unit) {
            case UnitType.Gram:
                return 1000;
            case UnitType.PieceAsDozen:
                return DOZEN;
            default:
                return 1;
        }
    },
    _isHalfDozen(portion, ratio) {
        return portion.quantity / ratio === 0.5;
    },
    getFormattedQuantityUnit(portion) {
        const ratio = this._getRatio(portion.unit);
        switch (portion.unit) {
            case UnitType.Gram:
                return Math.abs(portion.quantity) < ratio ? "g" : "kg";
            case UnitType.Piece:
                return "pièce" + (portion.quantity > 1 ? "s" : "");
            case UnitType.PieceAsDozen:
                if (this._isHalfDozen(portion, ratio)) return "demi-douzaine";
                else return "douzaine" + (portion.quantity / ratio > 1 ? "s" : "");
            default:
                return "";
        }
    },
    getFormattedQuantityValue(portion) {
        const ratio = this._getRatio(portion.unit);
        switch (portion.unit) {
            case UnitType.Gram:
                return Math.abs(portion.quantity) < ratio ? portion.quantity : portion.quantity / 1000;
            case UnitType.PieceAsDozen:
                if (this._isHalfDozen(portion, ratio)) return (portion.quantity / DOZEN) * 2;
                else return portion.quantity / DOZEN;
            case UnitType.Piece:
            default:
                return portion.quantity;
        }
    },
    getFormattedQuantityUnitAndValue(portion) {
        if (!portion) return;

        return this.getFormattedQuantityValue(portion) + " " + this.getFormattedQuantityUnit(portion);
    },
    getItemTagContent(item) {
        let tag = "";
        if (item.role === "optional") {
            return "ajouté si calibre trop petit";
        } else {
            const portion = this.getItemPortion(item);

            if (item.meta.display && item.meta.display.packaging && portion.unit === UnitType.Piece) {
                tag = this.getFormattedPackaging(portion, item.meta.display.packaging);
            } else {
                tag = this.getFormattedPublicQuantity(item);
            }
        }
        return tag;
    },
    getItemPortion(item) {
        return item.portion || this.getPortion(item.allocations, item.preAllocations);
    },
    getMethodTag(method) {
        return displayMethods[method];
    },
    getItemNameAttributes(item) {
        const isOptional = item.role === "optional";
        const unitPortion =
            item.portion ||
            (item.allocations[0] && item.allocations[0].slicing.portion) ||
            (item.preAllocations[0] && item.preAllocations[0].slicing.portion);
        if (item.publicPortionQuantity?.min) unitPortion.quantity = item.publicPortionQuantity.min;
        let caliber = this.getOystersCaliber(item);
        if (!caliber && item.product.type === "Coquillage") {
            caliber = `, ${this.getFormattedQuantityUnitAndValue(unitPortion)}`;
        }

        let pieceCount = "";
        if (unitPortion && unitPortion.unit === UnitType.Piece && item.product.type !== ProductType.Utensil) {
            pieceCount = `, ${this.getFormattedCaliber(item.meta.caliber, unitPortion)}`;
        } else if (unitPortion && unitPortion.unit === UnitType.Gram) {
            pieceCount = item.pieceCount && !isOptional ? `, ${this.getFormattedPieceCount(item.pieceCount)}` : "";
        }

        const name = this.getFormattedNameAndDisplay(item);

        return {name, pieceCount, caliber};
    },
    getItemName(item) {
        const {name, pieceCount, caliber} = this.getItemNameAttributes(item);
        return `${name}${pieceCount}${caliber}`;
    },
    getSimpleItemName(item) {
        const {name, caliber} = this.getItemNameAttributes(item);

        if (this.checkOysterName(item.product.name)) return `${name}${caliber}`;

        return name;
    },
    selectOfferingsItemsToDisplay(offerings) {
        return offerings.reduce((memo, item) => {
            const previousIndex = memo.findIndex((i) => {
                return (
                    item.product.id === i.product.id &&
                    item.meta.display &&
                    i.meta.display &&
                    item.meta.display.name === i.meta.display.name
                );
            });

            const previousItem = memo[previousIndex];
            const cloned = cloneDeep(item);

            if (!previousItem) {
                return [...memo, cloned];
            }

            if (item.role !== "optional") {
                if (previousItem.role === "optional") {
                    memo[previousIndex] = cloned;
                } else {
                    memo.push(cloned);
                }
            }
            return memo;
        }, []);
    },
    getOptionalItemName(productName) {
        const vowels = ["a", "e", "i", "o", "u", "y"];
        let prefix;
        if (
            vowels.includes(productName.toLowerCase()[0]) ||
            productName.toLowerCase().indexOf("huitre") !== -1 ||
            productName.toLowerCase().indexOf("huître") !== -1
        )
            prefix = "d'";
        else prefix = "de ";
        return `(Ajout ${prefix}${productName} si calibre trop petit)`;
    },
    getOystersCaliber(item) {
        if (item.product.name.indexOf("Huitre") !== -1 || item.product.name.indexOf("Huître") !== -1) {
            if (item.meta.caliber && item.meta.caliber.match(/^N\d$/)) {
                return `, numéro ${item.meta.caliber.slice(-1)}`;
            }
            return item.meta.caliber ? ", " + item.meta.caliber : "";
        }
        return "";
    },
    getFormattedPieceCount(pieceCount) {
        if (pieceCount.indexOf("-") !== -1) return `${pieceCount.split("-")[0]} à ${pieceCount.split("-")[1]} pièces`;
        else return parseInt(pieceCount, 10) > 1 ? `${pieceCount} pièces` : "1 pièce";
    },
    getFormattedPackaging(portion, packaging) {
        if (packaging === "Sous vide")
            return portion.quantity + ` pièce${portion.quantity > 1 ? "s " : " "}` + packaging.toLowerCase();
        return portion.quantity + " " + packaging.toLowerCase() + (portion.quantity > 1 ? "s" : "");
    },
    getFormattedWeight(value) {
        const portion = {unit: UnitType.Gram, quantity: value};
        return this.getFormattedQuantityValue(portion) + this.getFormattedQuantityUnit(portion);
    },
    getFormattedCaliber(caliber) {
        if (caliber.indexOf("+") !== -1) {
            if (caliber.indexOf("/") !== -1)
                return `environ ${this.getFormattedWeight(Number(caliber.split("/")[1].replace("+", "")))}`;
            else return `environ ${this.getFormattedWeight(Number(caliber.replace("+", "")))}`;
        } else if (caliber.indexOf("/") !== -1 && Number(caliber.split("/")[0]) !== Number(caliber.split("/")[1])) {
            // eslint-disable-next-line max-len
            return `de ${this.getFormattedWeight(Number(caliber.split("/")[0]))} à ${this.getFormattedWeight(
                Number(caliber.split("/")[1]),
            )}`;
        } else if (caliber.indexOf("/") !== -1 && Number(caliber.split("/")[0]) === Number(caliber.split("/")[1])) {
            return `${this.getFormattedWeight(Number(caliber.split("/")[0]))}`;
        } else if (caliber && !Number.isNaN(caliber)) {
            return `${this.getFormattedWeight(Number(caliber))}`;
        } else {
            return "";
        }
    },
    getRatio(unit) {
        switch (unit) {
            case UnitType.Gram:
                return 1000;
            case UnitType.PieceAsDozen:
                return DOZEN;
            default:
                return 1;
        }
    },
    getItemSimpleName(item) {
        return this.getFormattedNameAndDisplay(item);
    },
    getFormattedNameAndDisplay(item) {
        const {
            meta: {display, bio},
            product,
        } = item;

        if (product.type === "Ustensile") return product.name;

        const vowels = ["a", "e", "i", "o", "u", "y", "h"];

        let name = "";
        const agreement = displayAgreements[display.name];
        if (agreement) {
            if (agreement.before && vowels.includes(product.name.toLowerCase()[0]))
                name = `${display.name} d'{nameLowerCase}`;
            else if (agreement.before) name = `${display.name} de {nameLowerCase}`;
            else if (product.feminine || product.plural)
                name = `{name} ${(
                    agreement[`${product.feminine ? "feminine" : ""}${product.plural ? "plural" : ""}`] || ""
                ).toLowerCase()}`;
        }

        if (!name) {
            let suffix = display.name;
            if (display.type === "Cuit") {
                let type = product.feminine ? "cuite" : "cuit";
                if (product.plural) type += "s";
                suffix = `${type}, ${display.name.toLowerCase()}`;
            }

            name = `{name}, ${suffix}`;
        }

        if (display.type === "Fumé") {
            if (product.feminine) name += " fumée";
            else name += " fumé";
            if (product.plural && name.slice(-1) !== "s") name += "s";
        }
        if (display.type === "Fumé à chaud") {
            if (product.plural && product.feminine) name += " fumées à chaud";
            else if (product.plural) name += " fumés à chaud";
            else if (product.feminine) name += " fumée à chaud";
            else name += " fumé à chaud";
        }

        if (bio) name += ", BIO";

        return name.replace("{nameLowerCase}", product.name.toLowerCase()).replace("{name}", product.name);
    },
    getAverageCaliberWeight(caliber) {
        if (caliber.indexOf("+") !== -1) {
            if (caliber.indexOf("/") !== -1) return Number(caliber.split("/")[1].slice(0, -1));
            else return Number(caliber.slice(0, -1));
        } else if (caliber.indexOf("/") !== -1) {
            return (Number(caliber.split("/")[0]) + Number(caliber.split("/")[1])) / 2;
        } else if (caliber && !Number.isNaN(caliber)) {
            return Number(caliber);
        }
    },
    getMinimumCaliberWeight(caliber) {
        if (!caliber) return;
        if (caliber.indexOf("+") !== -1) {
            if (caliber.indexOf("/") !== -1) return caliber.split("/")[0].slice(0, -1) + "+";
            else return caliber.slice(0, -1) + "+";
        } else if (caliber.indexOf("/") !== -1) {
            if (caliber.split("/")[0] !== caliber.split("/")[1]) return caliber.split("/")[0] + "+";
            else return caliber.split("/")[0];
        } else if (caliber && !Number.isNaN(caliber)) {
            return caliber;
        }
    },
    getPieceCount(item) {
        const isSaleOffering = item.portion;
        const portion = isSaleOffering ? item.portion : item.allocations[0] && item.allocations[0].slicing.portion;
        const unit = portion.unit;
        if (unit !== UnitType.Gram) return;

        const average = this.getAverageCaliberWeight(item.meta.caliber);
        const pieceCount = portion.quantity / average;
        if (!pieceCount) return;

        let pieceCountString;
        if (((pieceCount % 1 < 0.15 || pieceCount % 1 > 0.85) && average > 300) || Number.isInteger(pieceCount))
            pieceCountString = Math.round(pieceCount).toString();
        else pieceCountString = Math.floor(pieceCount) + "-" + Math.ceil(pieceCount);
        if (pieceCountString.charAt(0) === "0") pieceCountString = "1";

        return pieceCountString;
    },
    toUnitPrice(item, price) {
        const portion =
            item.portion ||
            (item.allocations[0] && item.allocations[0].slicing.portion) ||
            (item.preAllocations[0] && item.preAllocations[0].slicing.portion);
        const minCaliber = this.getMinimumCaliberWeight(item.meta.caliber) || "";

        if (portion.unit === UnitType.PieceAsDozen) {
            return `${Math.round(price / 10 / (portion.quantity / this._getRatio(portion.unit))) / 10}€/douzaine`;
        }
        if (portion.unit === UnitType.Piece && minCaliber.indexOf("+") !== -1) {
            return `max ${Math.round(((1000 / Number(minCaliber.slice(0, -1))) * price) / 10) / 10}€/kg`;
        }
        if (portion.unit === UnitType.Piece && minCaliber) {
            if (
                ["Algue", "Herbe", "Divers"].includes(item.product.type) &&
                Math.round(((1000 / Number(minCaliber)) * price) / 100) > unitPriceBy100gThreshold
            )
                return `${Math.round(((1000 / Number(minCaliber)) * price) / 10) / 100}€/100g`;
            return `${Math.round(((1000 / Number(minCaliber)) * price) / 10) / 10}€/kg`;
        }
        if (portion.unit === UnitType.Gram) {
            if (
                ["Algue", "Herbe", "Divers"].includes(item.product.type) &&
                Math.round(price / 100 / (portion.quantity / this._getRatio(portion.unit))) > unitPriceBy100gThreshold
            )
                return `${Math.round(price / 10 / (portion.quantity / this._getRatio(portion.unit))) / 100}€/100g`;
            return `${Math.round(price / 10 / (portion.quantity / this._getRatio(portion.unit))) / 10}€/kg`;
        }
        return "";
    },
    mergeCaliber(itemA, itemB) {
        const itemACaliber = itemA.meta.caliber;
        let itemAFromCaliber = itemACaliber;
        let itemAToCaliber = itemACaliber;
        if (itemACaliber?.indexOf("/") !== -1) {
            itemAFromCaliber = itemACaliber.split("/")[0];
            itemAToCaliber = itemACaliber.split("/")[1];
        }

        const itemBCaliber = itemB.meta.caliber;
        let itemBFromCaliber = itemBCaliber;
        let itemBToCaliber = itemBCaliber;
        if (itemBCaliber?.indexOf("/") !== -1) {
            itemBFromCaliber = itemBCaliber.split("/")[0];
            itemBToCaliber = itemBCaliber.split("/")[1];
        }

        itemAFromCaliber = itemAFromCaliber.replace("+", "");
        itemBFromCaliber = itemBFromCaliber.replace("+", "");
        itemAFromCaliber = Number(itemAFromCaliber) < Number(itemBFromCaliber) ? itemAFromCaliber : itemBFromCaliber;

        let caliberSuffix = "";
        if (itemAToCaliber?.indexOf("+") !== -1 || itemBToCaliber?.indexOf("+") !== -1) {
            itemAToCaliber = itemAToCaliber.replace("+", "");
            itemBToCaliber = itemBToCaliber.replace("+", "");
            caliberSuffix = "+";
        }
        itemAToCaliber = Number(itemAToCaliber) > Number(itemBToCaliber) ? itemAToCaliber : itemBToCaliber;
        itemAToCaliber += caliberSuffix;

        return itemAFromCaliber === itemAToCaliber ? itemAFromCaliber : `${itemAFromCaliber}/${itemAToCaliber}`;
    },
    getShrinkedItems(items) {
        return items.reduce((memo, item) => {
            const firstItem = memo.find((_item) => {
                return (
                    _item.supplier.id === item.supplier.id &&
                    _item.product.id === item.product.id &&
                    _item.meta.display &&
                    item.meta.display &&
                    _item.meta.display.name === item.meta.display.name &&
                    _item.meta.method === item.meta.method
                );
            });

            if (firstItem) {
                const isSaleOffering = firstItem.portion;
                const itemPortion = isSaleOffering
                    ? item.portion
                    : item.allocations[0] && item.allocations[0].slicing.portion;
                const firstItemPortion = isSaleOffering
                    ? firstItem.portion
                    : firstItem.allocations[0] && firstItem.allocations[0].slicing.portion;

                const minFirstItemQuantity = firstItem.publicPortionQuantity.min || firstItemPortion.quantity;
                const minItemQuantity = item.publicPortionQuantity.min || itemPortion.quantity;
                const maxFirstItemQuantity = firstItem.publicPortionQuantity.max || firstItemPortion.quantity;
                const maxItemQuantity = item.publicPortionQuantity.max || itemPortion.quantity;

                firstItem.publicPortionQuantity = {
                    min: minFirstItemQuantity + minItemQuantity,
                    max: maxFirstItemQuantity + maxItemQuantity,
                };

                firstItemPortion.quantity += itemPortion.quantity;
                firstItem.meta.caliber = this.mergeCaliber(firstItem, item);
                firstItem.pieceCount = this.getPieceCount(firstItem);
            } else {
                memo.push(cloneDeep(item));
            }

            return memo;
        }, []);
    },
    soldoutOfferingTagLabel(stock) {
        return stock < 10 ? `plus que ${stock} disponible${stock > 1 ? "s" : ""}` : "";
    },
    checkIfSameValue(value) {
        const splitted = value.split("/");

        if (splitted.every((s) => splitted[0] === s)) {
            return [splitted[0]];
        }

        return splitted;
    },
    getSelectorCaliber({caliber}) {
        const caliberSplitted = caliber.replace("+", "").split("/");

        // when same value return only one
        if (caliberSplitted.every((s) => caliberSplitted[0] === s)) {
            return caliberSplitted[0] >= 1000 ? `${caliberSplitted[0] / 1000}kg` : `${caliberSplitted[0]}g`;
        }

        return this.setUnitByRatio(caliberSplitted);
    },
    setUnitByRatio(caliberSplitted) {
        const ratio = 1000;
        const kilo = Math.abs(caliberSplitted[0]) >= ratio;

        if (caliberSplitted.length === 1 && !kilo) {
            return caliberSplitted[0] + "g";
        } else if (caliberSplitted.length === 1 && kilo) {
            return Math.abs(caliberSplitted[0]) / ratio + "kg";
        } else if (!kilo) {
            return `${caliberSplitted[0]}/${caliberSplitted[1]}g`;
        } else {
            return `${caliberSplitted[0] / ratio}/${caliberSplitted[1] / ratio}kg`;
        }
    },
    getPortion(allocations, preAllocations) {
        const allocationsPortion = allocations && allocations[0] && allocations[0].slicing.portion;
        const preAllocationsPortion = preAllocations && preAllocations[0] && preAllocations[0].slicing.portion;

        return allocationsPortion || preAllocationsPortion;
    },
    checkIfOfferingShouldBeHidden(offering, place) {
        const hasSpiderCrab = offering.items.filter((item) => item.product.id === SPIDER_CRAB_PRODUCT_ID).length > 0;
        const isRegion = place?.shipping?.delay === 1;

        return config.hideSpiderCrabInRegion && isRegion && hasSpiderCrab;
    },
    checkOysterName(name) {
        return (name && name.toLowerCase().startsWith("huîtres")) || name.toLowerCase().startsWith("huitres");
    },
    getOfferingTags(restrictedChanel, offeringItem) {
        const productTypeList = [ProductType.Shell, ProductType.Fish, ProductType.Cephalopod, ProductType.Crustacean];
        if (restrictedChanel === RestrictedChannel.Combo) {
            return this.getItemTagContent(offeringItem);
        }

        const {meta, product, allocations, preAllocations, portion: shopPortion} = offeringItem;
        const portion = shopPortion || this.getPortion(allocations, preAllocations);

        const isUnitGram = portion && portion.unit === UnitType.Gram;
        const isUnitPieceAsDozen = portion && portion.unit === UnitType.PieceAsDozen;
        const isUtensil = product.type && product.type === ProductType.Utensil;
        const matchProduct = product.type && productTypeList.includes(product.type);
        const isPiece = portion && portion.unit === UnitType.Piece;

        if (isUtensil || (matchProduct && (isUnitGram || isUnitPieceAsDozen)) || meta.caliber.length === 0) {
            return "";
        }

        if (offeringItem.meta.display && offeringItem.meta.display.packaging && isPiece) {
            return this.getFormattedPackaging(portion, offeringItem.meta.display.packaging);
        }

        return this.getFormattedPublicQuantity(offeringItem);
    },
    getTotalPrice(offerings, priceOptionSelector) {
        return offerings.reduce((memo, offering) => {
            const {price} = priceOptionSelector(offering);
            return memo + offering.quantity * price.ttc;
        }, 0);
    },
    computePriceForDefaultPriceOption(price) {
        const tax = price.tax;
        const ttcWithMalus = price.ttc * MALUS;
        const rounded = (Math.ceil((Math.round(ttcWithMalus) / 100) * 2) / 2) * 100;

        return {
            ht: Math.round(rounded / (1 + tax / 100)),
            ttc: rounded,
            tax,
            currency: "eur",
        };
    },
    getDefaultComboPriceOptions() {
        return Object.entries(config.pricing.subscription).map(([rate, {price}]) => {
            const condition = rate === "uniq" ? {formula: "uniq"} : {formula: "subscription", rate};

            return {
                type: "subscriber",
                condition,
                price: {
                    ...price,
                    currency: "eur",
                    tax: 5.5,
                },
            };
        });
    },
    getFormattedPublicQuantity(item, portion = this.getItemPortion(item)) {
        const {publicPortionQuantity} = item;

        if (isEmpty(publicPortionQuantity)) {
            return this.getFormattedQuantityUnitAndValue(portion);
        } else {
            const {min, max} = publicPortionQuantity;
            if (min === max) {
                const minPortion = {...portion, quantity: min};
                return this.getFormattedQuantityUnitAndValue(minPortion);
            } else {
                const boundaries = [
                    {...portion, quantity: min},
                    {...portion, quantity: max},
                ];

                const isSameUnit =
                    uniq(boundaries.map((portion) => this.getFormattedQuantityUnit(portion))).length === 1;

                return boundaries
                    .map((portion, index) => {
                        if (index === 0 && isSameUnit) {
                            return this.getFormattedQuantityValue(portion);
                        }
                        return this.getFormattedQuantityUnitAndValue(portion);
                    })
                    .join("/");
            }
        }
    },
};

export default _;
