export const DOZEN = 12;

export const RestrictedChannel = {
    Shop: "shop",
    Combo: "combo",
};

export const UnitType = {
    Gram: "gram",
    Piece: "piece",
    PieceAsDozen: "pieceAsDozen",
};

export const ProductType = {
    Utensil: "Ustensile",
    Fish: "Poisson",
    Crustacean: "Crustacé",
    Cephalopod: "Céphalopode",
    Shell: "Coquillage",
    Seaweed: "Algue",
    Herb: "Herbe",
    Tool: "Ustensile",
    Misc: "Divers",
    Transformed: "Transformation",
    Mixed: "Mixte",
};

export const PauseReasons = {
    "holidays-no-pr": "Je pars en vacances / déplacement, dans un lieu sans point relais",
    "holidays-no-delivery": "Je pars en vacances / déplacement, je ne veux pas me faire livrer",
    "too-busy": "Trop occupé en ce moment, pas dispo pour récupérer / cuisiner mon Casier",
    "relay-closed-permanently": "Mon point relais a fermé définitivement, j’en cherche un nouveau",
    "relay-closed-temporarily": "Mon point relais a fermé temporairement, je reprends à son retour",
    "freezer-full": "Mon congel est plein, je reprends plus tard",
    "moving": "Je déménage et je suspends le temps de trouver un point relais",
    "prefer-shop-purchases": 'Je préfère faire des achats "à la carte" pour le moment',
    "waiting-seasonal-product": "J’attends la saison d’un produit en particulier pour reprendre",
    "disappointed": "Je suis déçu par Poiscaille, j’hésite à me désabonner",
};
