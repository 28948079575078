import addDays from "date-fns/add_days";
import format from "date-fns/format";
import parseDate from "date-fns/parse";

import Model from "../common/legacy-http/Model";
import _ from "../common/Reference";

class Subscription extends Model {
    constructor() {
        super({
            url: `${process.env.NEXT_PUBLIC_POISCAILLE_API_URL}/subscriptions`,
            props: {
                id: "string",
                formula: "string",
                rate: "string",
                quantity: "number",
                place: "object",
                price: "number",
                late: "object",
                delivery: "object",
                startingAt: "string",
                allergies: "object",
                upcoming: "object",
                freeDelivery: "boolean",
                token: "string",
                paused: "object",
                subscribed: "string",
            },
        });
    }
    parse(data) {
        if (data.delivery) {
            data.delivery.formattedAt = Subscription.formattedAt(data);
            data.delivery.formattedAnd = Subscription.formattedAnd(data);

            if (data.delivery.formattedAt) data.delivery.formattedAt = format(data.delivery.formattedAt, "YYYY-MM-DD");

            if (data.delivery.formattedAnd)
                data.delivery.formattedAnd = format(data.delivery.formattedAnd, "YYYY-MM-DD");
        }
        if (data.place && data.place.opening && data.place.opening.closing) {
            data.place.opening.closing = {
                from: parseDate(data.place.opening.closing.from),
                to: parseDate(data.place.opening.closing.to),
                reason: data.place.opening.closing.reason,
            };
        }
        if (data.place && data.place.opening && data.place.opening.day && data.place.opening.schedule) {
            const place = data.place;
            place.opening.formattedSchedule = _.getFormattedSchedule(place, place.opening);
        }
        if (data.subscribed) data.subscribed = parseDate(data.subscribed);
        data.freeDelivery = _.subscriptionHasFreeDelivery(data.place, data.quantity);
        return data;
    }
    static formattedAt(item, at) {
        at = at || item.delivery?.at;

        if (item.isLate && item.late) {
            if (_.isPlaceShippedNextDay(item.late.place) && at) return addDays(at, 1);
        } else if (_.isPlaceShippedNextDay(item.place)) {
            if (at) return addDays(at, 1);
        }

        if (at) {
            return parseDate(at);
        }
    }
    static formattedAnd(item, and) {
        and = and || item.delivery?.and;

        if (item.late) {
            if (_.isPlaceShippedNextDay(item.place) && and) return addDays(and, 1);
        } else if (_.isPlaceShippedNextDay(item.place)) {
            if (and) return addDays(and, 1);
        }

        if (and) {
            return parseDate(and);
        }
    }
}

export default Subscription;
