export default class Sync {
    fetchOptions(opts) {
        opts = opts || {};
        opts.credentials = "include";

        const controller = new AbortController();
        setTimeout(() => controller.abort(), process.env.timeout);
        opts.signal = controller.signal;

        return opts;
    }
    searchUrl(qs) {
        return "?" + this.encodeJSONToUrl(qs);
    }
    encodeJSONToUrl(json, prefix) {
        let queryString = "";
        for (const key in json) {
            // eslint-disable-next-line no-prototype-builtins
            if (json.hasOwnProperty(key)) {
                if (queryString.length > 0 && queryString.slice(-1) !== "&") {
                    queryString += "&";
                }
                if (Array.isArray(json[key])) {
                    queryString += this.encodeArrayToUrl(key, json[key], prefix);
                } else if (typeof json[key] === "object") {
                    queryString += this.encodeJSONToUrl(json[key], encodeURIComponent(key));
                } else if (json[key] !== undefined) {
                    queryString +=
                        (prefix ? prefix + "[" + encodeURIComponent(key) + "]" : encodeURIComponent(key)) +
                        "=" +
                        encodeURIComponent(json[key]);
                }
            }
        }
        return queryString;
    }
    encodeArrayToUrl(key, array, prefix) {
        let queryString = "";
        for (let i = 0; i < array.length; i++) {
            if (queryString.length > 0 && queryString.slice(-1) !== "&") {
                queryString += "&";
            }
            if (typeof array[i] === "object") {
                queryString += this.encodeJSONToUrl(array[i], prefix);
            } else {
                queryString +=
                    (prefix ? prefix + "[" + encodeURIComponent(key) + "]" : encodeURIComponent(key)) +
                    "=" +
                    encodeURIComponent(array[i]);
            }
        }
        return queryString;
    }
    readJSON(res) {
        if (res.status >= 400) {
            return res.json().then((err) => {
                const error = new Error(err.message);
                error.name = err.type;
                throw error;
            });
        }

        return res.json();
    }
}
