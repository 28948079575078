export const config = {
    freeDeliveryThresholdPrice: {ttc: 8000, ht: 6667},
    freeDeliveryComboThreshold: 4,
    hideSpiderCrabInRegion: false,
    pricing: {
        subscription: {
            weekly: {price: {ttc: 2290, ht: 2171}},
            biweekly: {price: {ttc: 2490, ht: 2360}},
            fourweekly: {price: {ttc: 2690, ht: 2550}},
            uniq: {price: {ttc: 2790, ht: 2645}},
        },
        giftCard: {
            pricePerCombo: {ttc: 2490, ht: 2360},
        },
        fourweeklyVisibleUntil: "2024-08-21",
    },
};
